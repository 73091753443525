import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import LogoTitle from '../../assets/images/logo-I.png'
import './index.scss'
import headshot from '../../assets/images/me.jpg'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  const nameArray = ['s', 'a', 'a', 'c']

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 1000)
  }, [])

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <div className="name-text">
                <div className ="greeting-text">
                    <span className={letterClass}>H</span>
                    <span className={`${letterClass} _12`}>i</span>
                    <span className={`${letterClass} _12`}>,</span>
                </div>
                <br />
                <span className={`${letterClass} _13`}>I</span>
                <span className={`${letterClass} _14`}>'</span>
                <span className={`${letterClass} _14`}>m</span>
                <img
                src={LogoTitle}
                alt="JavaScript Developer Name"
                />
                <AnimatedLetters
                letterClass={letterClass}
                strArray={nameArray}
                idx={15}
                />
            </div>
          </h1>
          <h2>Software Engineer / Web Developer / Student</h2>
          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>
        </div>  
        <div className="headshot-container">
            <img
                src={headshot}
                alt="My Headshot"
                className="headshot"
            />
        </div>
      </div>

      <Loader type= "line-scale-pulse-out" />
    </>
  )
}

export default Home
