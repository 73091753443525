import { useEffect, useState, useRef } from 'react'
import emailjs from '@emailjs/browser'
import Loader from 'react-loaders'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const [isSending, setIsSending] = useState(false) // Track button state
    const form = useRef()

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)

        return () => clearTimeout(timer)
    }, [])

    const sendEmail = (e) => {
        e.preventDefault()
        setIsSending(true) // Disable the button and change text

        emailjs
            .sendForm(
                'service_eddn63z',
                'template_k0c955j',
                form.current,
                'MWlWsi74FQUm14Z_r'
            )
            .then(
                () => {
                    alert('Message successfully sent!')
                    setIsSending(false) // Re-enable the button
                    window.location.reload(false)
                },
                (error) => {
                    console.error('EmailJS Error:', error)
                    alert('Failed to send the message, please try again.')
                    setIsSending(false) // Re-enable the button
                }
            )
    }

    return (
        <>
            <div className="container contact-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'M', 'e']}
                            idx={15}
                        />
                    </h1>
                    <p>
                        I am interested in freelance opportunities, part-time job opportunities, and internships. Please reach out if
                        you feel I would be a good fit for your project/team or if you have any questions.
                    </p>
                    <div className="contact-form">
                        <form ref={form} onSubmit={sendEmail}>
                            <ul>
                                <li className="half">
                                    <input placeholder="Name" type="text" name="name" required />
                                </li>
                                <li className="half">
                                    <input
                                        placeholder="Email"
                                        type="email"
                                        name="email"
                                        required
                                    />
                                </li>
                                <li>
                                    <input
                                        placeholder="Subject"
                                        type="text"
                                        name="subject"
                                        required
                                    />
                                </li>
                                <li>
                                    <textarea
                                        placeholder="Message"
                                        name="message"
                                        required
                                    ></textarea>
                                </li>
                                <li>
                                    <input
                                        type="submit"
                                        className={`flat-button ${isSending ? 'sending' : ''}`}
                                        value={isSending ? 'SENDING...' : 'SEND'}
                                        disabled={isSending}
                                    />
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
                <div className="info-map">
                    Isaac Adams
                    <br />
                    Raleigh / Charlotte, NC
                    <br />
                    United States
                    <br />
                    <br />
                    <span>j.isaac.adams@icloud.com</span>
                </div>
                <div className="map-wrap">
                    <MapContainer center={[35.783835, -78.670851]} zoom={15}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position={[35.783835, -78.670851]}>
                            <Popup>I am currently studying Computer Science at NC State!</Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
            <Loader type="line-scale-pulse-out" />
        </>
    )
}

export default Contact

