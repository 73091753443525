import React, { useEffect, useState } from "react";
import Loader from "react-loaders";
import AnimatedLetters from "../AnimatedLetters";
import ProjectCard from "../ProjectCard/index.js"; 
import "./index.scss";

const Portfolio = () => { 
    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const projects = [
        {
            title: "New York City AirBnB AI Price Predictor",
            description: "New York City’s Airbnb market is as exciting as it is unpredictable, and this project is our way of making sense of it. Our focus is on a single, practical question: How much should you expect to pay for a listing? By building a price prediction model, we aim to create a tool that helps travelers and hosts alike understand the factors that influence the cost of a stay.",
            credits: "Co-Dev: August Pallesen",
            repoLink: "https://github.com/j-isaac-adams/nyairbnb"
        }
    ];

    return (
        <>
            <div className="container portfolio-page">
                <h1 className="page-title">
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={"Projects".split("")}
                        idx={15}
                    />
                </h1>
                <div className="projects">
                    {projects.map((project, index) => (
                        <ProjectCard 
                            key={index}
                            title={project.title}
                            description={project.description}
                            credits={project.credits}
                            repoLink={project.repoLink}
                        />
                    ))}
                </div>
            </div>
            <Loader type="line-scale-pulse-out" />
        </>
    );
};

export default Portfolio;