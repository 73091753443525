import React from "react";
import "./index.scss";

const ProjectCard = ({ title, description, credits, repoLink }) => {
    return (
        <div className="project-card">
            <h2 className="project-title">{title}</h2>
            <p className="project-description">{description}</p>
            <h3 className="project-credits">{credits}</h3>
            <a href={repoLink} target="_blank" rel="noopener noreferrer" className="project-button">
                View Project
            </a>
        </div>
    );
};

export default ProjectCard;